import React from 'react';
import {Binder, BinderId, Drug, DrugId} from "./models/Models";
import "./FullScreenModal.css"
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import {EventBackendService} from "../register/v2/EventBackendService";

interface props {
    binder: Binder | undefined
    flipModal: () => void;
    organisation: Organisation
    update: () => void;
}

interface state {
    drugs: Drug[],
    valueMap: Map<DrugId, { amount: string, newCount: string }>;
    comment: string | undefined,
    showComment: boolean
}

class FullScreenModal extends React.Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            drugs: [],
            valueMap: new Map(),
            comment: undefined,
            showComment: false,
        }
    }

    async componentDidMount() {
        let organisation: string = this.props.organisation.organisationId;

        const drugsPath: string = `/api/v1/knarkbok/drugs/${organisation}`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);
        const drugsResponse: any = await this.context.get(drugUrl, {
            success: "",
            failure: ""
        }) as any;

        if (drugsResponse !== undefined && drugsResponse.data !== undefined) {
            this.setState({
                drugs: drugsResponse.data,
            });
        }
    }

    render(): React.JSX.Element {
        const {props} = this;

        const commentFlip = <div
            className={"ml-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"}
                    onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pb-1"}>
                <div className={"col m-0 p-0"}>
                    <div className={"row m-0"}>
                        Kommentar:
                        {commentFlip}
                    </div>
                </div>
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row ml-0 pl-0 pr-3 mb-2"}>
                        <textarea value={this.state.comment}
                                  rows={3}
                                  className={"max-size-input"}
                                  onChange={(e) => this.handleChangeComment(e)}
                        />
                    </div>
                </React.Fragment> : <div/>
            }

        </div>

        return (<div>
                <aside className="knarkbok-modal-background-fullscreen" onClick={props.flipModal}/>
                <div className={"knarkbok-modal-fullscreen"}>
                    <div className={"container p-0 m-0 justify-content-center"}>
                        <div aria-label={'headline'}
                             className={"row fullscreen-header-color"}>
                            <div className={"col-5"}>
                                <h5 className={"mt-0"}>
                                    Läkemedel
                                </h5>
                            </div>
                            <div className={"col-4"}>
                                <h5 className={"mt-0"}>
                                    Antal från apoteket
                                </h5>
                            </div>
                            <div className={"col-3 pl-1"}>
                                <h5 className={"mt-0"}>
                                    Nytt saldo
                                </h5>
                            </div>
                        </div>

                        <div className={"medicine-list pt-2"}>
                            {this.state.drugs.map((drug) => {
                                return <div className={"row m-0 pb-2"}>
                                    <div className={"col-5 pl-0"}>{drug.name}</div>
                                    <div className={"col-4"}>
                                        <input value={this.getAmount(drug.id)}
                                               onChange={(e) => this.updateAmount(e, drug.id)}
                                               type={"text"}
                                               size={2}/>
                                    </div>
                                    <div className={"col-3"}>
                                        <input value={this.getCount(drug.id)}
                                               onChange={(e) => this.updateCount(e, drug.id)}
                                               type={"text"}
                                               size={2}/>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    {commentField}
                    <div className={"row from-bottom-rel justify-content-end"}>
                        <button aria-label={'ok'}
                                onClick={() => this.sendDelivery()}
                                className={"btn btn-tage-default mr-3"}>
                            OK
                        </button>
                        <button aria-label={'cancel'}
                                onClick={props.flipModal}
                                className={"btn btn-tage-default-cancel mr-3"}>
                            Avbryt
                        </button>
                    </div>
                </div>
            </div>

        );
    }

    private getAmount(id: DrugId | undefined): string {
        if (id) {
            let amount = this.state.valueMap.get(id);
            if (amount) {
                return "" + amount.amount;
            }
        }
        return "";
    }

    private updateAmount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        if (id) {
            let valueMap = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.amount = stripped;
            } else {
                valueMap.set(id, {amount: stripped, newCount: ""})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private getCount(id: DrugId | undefined): string {
        if (id) {
            let count = this.state.valueMap.get(id);
            if (count) {
                return "" + count.newCount;
            }
        }
        return "";
    }

    private updateCount(e: React.ChangeEvent<HTMLInputElement>, id: DrugId | undefined) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        if (id) {
            let valueMap: Map<DrugId, {
                amount: string;
                newCount: string
            }> = this.state.valueMap;

            let exists = valueMap.get(id);
            if (exists) {
                exists.newCount = stripped;
            } else {
                valueMap.set(id, {amount: "", newCount: stripped})
            }

            this.setState({valueMap: valueMap})
        }
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private async sendDelivery() {
        const {valueMap} = this.state;
        const binder = this.props.binder;
        let payload: {
            binderId: BinderId | undefined,
            organisationId: string,
            comment: string | undefined,
            drugs: {
                drugId: DrugId,
                amount: string,
                newBalance: string
            }[]
        } = {
            binderId: binder?.id,
            organisationId: this.props.organisation.organisationId,
            comment: this.state.comment,
            drugs: []
        }

        for (const entry of valueMap) {
            payload.drugs.push({
                drugId: entry[0],
                amount: entry[1].amount,
                newBalance: entry[1].newCount
            })
        }

        const drugsPath: string = `/api/v1/knarkbok/top-up-drugs/`
        const drugUrl: string = EventBackendService.getUrl2(drugsPath);

        let topUpResponse = await this.context.post(drugUrl, JSON.stringify(payload), {
            success: "",
            failure: ""
        });

        if (topUpResponse.success) {
            this.props.update();
        }

        this.props.flipModal();
    }
}

export default FullScreenModal;
