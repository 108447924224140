export function validateDate(sample: string, format: string): boolean {
    if (sample === "") {
        return true;
    }

    if (sample.length !== format.length) {
        return false;
    }

    let date: string = harmonize(sample, format);

    // dates are always on the format yyyy-mm-dd
    const regex = /\d{4}-\d{2}-\d{2}/gm;
    if (!regex.test(date)) {
        return false;
    }

    let parts: string[] = date.split("-");
    let day: number = parseInt(parts[2], 10);
    let month: number = parseInt(parts[1], 10);
    let year: number = parseInt(parts[0], 10);

    if (year < 1000 || year > 3000 || month === 0 || month > 12 || day < 1) {
        return false;
    }

    let monthLength: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // compensate for leap year
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
    }

    return day <= monthLength[month - 1];
}

function harmonize(sample: string, format: string): string {
    if (format === "yyyy-MM-dd") {
        return sample;
    }

    if (format === "yyMMdd") {
        let replacedDate: string;
        if (sample.length !== 6) {
            replacedDate = "";
        } else {
            replacedDate = "20" + sample[0] + sample[1] + "-" + sample[2] + sample[3] + "-" + sample[4] + sample[5];
        }

        return replacedDate;
    }

    throw new Error("Unable to harmonize " + sample + " for the date format " + format);
}
