import React from "react";
import {withRouter} from 'react-router-dom';
import "./FullNavBar.css";
import {UserData} from "../model/UserData";
import {NavBar} from "../model/NavBar";
import {User} from "../model/User";
import {Organisation} from "../model/Organisation";
import {BackendContext, BackendInterface} from "../../infra/BackendContext";
import FullNavBarMobile from "./FullNavBarMobile";
import FullNavBarDesktop from "./FullNavBarDesktop";

interface Position {
    id: string,
    yPos: number
}

interface props {
    user: User
    updateNavBar: (navBar: NavBar) => void
    setCurrentOrganisation: (organisation: Organisation | undefined) => void
    userData: UserData,
    refreshExpiredToken: () => void,
    securityToken: string,
    setAction: (action: string) => void,
    setStatsAction: (statsAction: string) => void,
    history: string[],
    setPath: (path: string) => void,
    nav: any //TODO make interface
}

interface state {
    rootLinksInvisible: boolean
    ypos: number,
    hasScrolled: boolean,
    userData: UserData,
    sublistYpos: Position[]
    width: number,
    height: number
}

class FullNavBarHub extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            ypos: 0,
            hasScrolled: false,
            userData: this.props.userData,
            rootLinksInvisible: false,
            sublistYpos: [],
            width: 0,
            height: 0
        };


        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    render(): React.JSX.Element {
        const {
            user,
            nav,
            updateNavBar,
            setCurrentOrganisation,
            userData,
            refreshExpiredToken,
            securityToken,
            setAction,
            setPath,
            setStatsAction
        } = this.props;

        if (this.state.width < 992) {
            return <FullNavBarMobile user={user}
                                     nav={nav}
                                     updateNavBar={updateNavBar}
                                     setCurrentOrganisation={setCurrentOrganisation}
                                     userData={userData}
                                     refreshExpiredToken={refreshExpiredToken}
                                     securityToken={securityToken}
                                     setAction={setAction}
                                     setPath={setPath}
                                     setStatsAction={setStatsAction}
            />
        } else {
            return <FullNavBarDesktop user={user}
                                      nav={nav}
                                      updateNavBar={updateNavBar}
                                      setCurrentOrganisation={setCurrentOrganisation}
                                      userData={userData}
                                      refreshExpiredToken={refreshExpiredToken}
                                      securityToken={securityToken}
                                      setAction={setAction}
                                      setPath={setPath}
                                      setStatsAction={setStatsAction}
            />
        }
    }

    //TODO move org parts up to hub
}

export default withRouter(FullNavBarHub);
