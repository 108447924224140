import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import {KnarkbokService} from "../Knarkbokservice";
import {KnarkbokType} from "../models/Models";
import "./Move.css";
import {EventBackendService} from "../../register/v2/EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";

interface props {
    closeModal: () => void;
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    isDruglord: boolean;
}

interface state {
    spinner: boolean,
    newBalance: string | undefined,
    displayCheckWarning: boolean
    comment: string | undefined;
    showComment: boolean;
}

class Check extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            spinner: false,
            newBalance: "",
            displayCheckWarning: false,
            comment: undefined,
            showComment: false
        }
    }

    render() {
        if (this.state.spinner) {
            return this.getSpinner()
        }

        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pb-1 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0 p-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input m-0"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>
        //TODO this part is repeated in all innermodals, and as such should be outsourced
        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>
                <button aria-label={'ok'}
                        disabled={!(this.state.newBalance || this.state.comment)}
                        className={"btn btn-tage-default"}
                        onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={this.props.closeModal}>
                    Avbryt
                </button>
            </div>;

        return (
            <div className={"container m-0 p-0"}>
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        <div className={"row m-0 pb-1"}>
                            <div className={"col m-0 p-0"}>
                                Nytt saldo:
                            </div>
                        </div>
                        <div className={"row m-0 pb-1"}>
                            <div className={"col m-0 p-0"}>
                                <input aria-label={'new balance'}
                                       value={this.state.newBalance}
                                       className={"max-size-input p-0"}
                                       onChange={(e) => this.handleCheck(e)}
                                       type={"text"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col"}></div>
                </div>
                <div className={"row"}>
                    <div className={"col"}></div>
                    <div className={"col-6 m-0 p-0"}>
                        {commentField}
                    </div>
                    <div className={"col"}></div>
                </div>
                {warningStuff}
                {submitCancelButtons}
            </div>
        );
    }

    private getSpinner() {
        return <div aria-label={"administer-drug-spinner"}
                    className={"spinner"}/>;
    }

    private submit = async (forceSubmit?: boolean) => {
        if (this.props.book?.bookId) {
            const isDrugLord: boolean = this.props.isDruglord;
            const newBalance: string | undefined = this.state.newBalance;
            const oldInventory: number = this.props.book.inventory;
            const check: boolean = this.props.KBService.aboutToSubmitCheck(oldInventory, newBalance + '', KnarkbokType.check);

            if (check || forceSubmit || isDrugLord) {
                let type: KnarkbokType = KnarkbokType.check;
                if (isDrugLord) {
                    type = KnarkbokType.druglord_check;
                }
                const book: ModalDisplayBinder = this.props.book;
                const comment: string | undefined = this.state.comment;
                const path: string = `/api/v1/knarkbok/events/`
                const url = EventBackendService.getUrl2(path);

                let payload = {
                    eventType: KnarkbokType[type],
                    bookId: book.bookId,
                    amount: 0,
                    newBalance: newBalance,
                    comment: comment
                };

                let success: string = '';
                if (forceSubmit) {
                    success = 'Mail om avvikelse skickas till ansvarig';
                }

                this.setState({
                    spinner: true,
                });

                await this.context.post(url, JSON.stringify(payload), {
                    success: success,
                    failure: ""
                })

                this.setState({
                    spinner: false,
                });

                this.props.closeModal();
            } else {
                this.setState({displayCheckWarning: true});
            }
        }
    }

    handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
        const stripped = e.currentTarget.value.replace(/\D/g, '');
        this.setState({newBalance: stripped})
    }


    private closeWarning() {
        this.setState({displayCheckWarning: false})
    }

    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

}

export default Check;
