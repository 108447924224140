import React from "react";
import {Binder, BinderId, Book, BookId, KnarkbokType} from "./models/Models";
import KnarkbokModal from "./KnarkbokModal";
import "./KnarkbokModal.css"
import "./ToggleSwitch.css"
import "./Knarkbokuserpage.css"
import {KnarkbokService} from "./Knarkbokservice";
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import FullScreenModal from "./FullScreenModal";

interface props {
    KBService: KnarkbokService
    organisation: Organisation
    drugUser: boolean
    drugLord: boolean
    drugBook: string
}

interface state {
    books: Book[],
    binders: Binder[] | undefined;
    administer: boolean,
    move: boolean,
    discard: boolean,
    check: boolean,
    currentId: BookId | null,
    currentBinder: Binder | undefined,
    isDrugLord: boolean
    acceptDelivery: boolean
    showDeliveryPopup: boolean
}

class Knarkbokuserpage extends React.Component<props, state> {
    KBService: KnarkbokService;
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            books: [],
            check: false,
            administer: false,
            move: false,
            discard: false,
            currentId: null,
            currentBinder: undefined,
            binders: undefined,
            isDrugLord: false,
            acceptDelivery: false,
            showDeliveryPopup: false
        }
        //TODO move all pointers from the local one to props version
        this.KBService = this.props.KBService;
    }

    componentDidMount() {
        this.getBinder();
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        // Fetch the drug book even if we haven't logged out.
        // The user may have just scanned a new qr code a second after the first one.
        if (prevProps.drugBook !== this.props.drugBook) {
            this.getBinder();
        }
    }

    render() {
        const state = this.state;
        let dispBook = <div/>;
        let actionModal = <div/>;
        let deliveryModal = <div/>;
        let binders = <div/>;
        const organisation: Organisation = this.props.organisation;

        if (state.discard || state.check || state.administer || state.move) {
            const type = this.setType(state);

            actionModal =
                <KnarkbokModal isDruglord={this.state.isDrugLord}
                               KBService={this.KBService}
                               flipModal={this.turnOffModal}
                               type={type}
                               bookId={this.state.currentId}
                               organisation={organisation}
                />;
        }

        if (state.showDeliveryPopup) {
            deliveryModal = <FullScreenModal flipModal={this.turnOffDeliveryModal}
                                             binder={this.state.currentBinder}
                                             update={this.getBinder}
                                             organisation={organisation}/>;
        }

        let druglordSwitch = <div/>
        if (this.props.drugLord) {
            druglordSwitch =
                <div className={"pb-3"}><span
                    className={"span-text"}>Jag loggar i egenskap av narkotikaansvarig</span><label
                    className="switch">
                    <input type="checkbox"
                           defaultChecked={false}
                           onChange={() => this.handleDruglord()}
                    />
                    <span className="slider round"/>
                </label></div>;
        }

        const deliveryButton = this.state.acceptDelivery ? <div className={"kb-position-relative"}>
            <div className={"col m-0 p-0"}>
                <button onClick={() => this.flipShowDelivery()}
                        className={"btn btn-tage-default btn-tage-green"}>
                    Påfyllning från apoteket
                </button>
            </div>
        </div> : "";

        const binderHeader = <div className={"row m-0 p-0"}>
            <div className={"col-4 m-0 p-0"}>
                <div aria-label={'drug-book-header'}
                     className={"pb-1"}>
                    <h4>{this.state.currentBinder?.locationName}</h4>
                </div>
            </div>
            <div className={"col-8 m-0 p-0 text-right"}>
                {deliveryButton}
            </div>
        </div>;

        const cancelButton = <div className={"col m-0 p-0"}>
            <div className={"row m-0 pl-3 pb-2 pt-2"}>
                <button aria-label={'cancel button'}
                        onClick={() => this.routeHome()}
                        className="btn btn-tage-default">
                    Avsluta
                </button>
            </div>
        </div>;

        const books: Book[] = this.state.books;
        if (books.length > 0) {
            const buttonClass = "btn btn-tage-somethingelse";
            dispBook = <div className={"userpage-box container p-0"}>
                {druglordSwitch}
                {
                    books.map((book: Book) => {

                        let buttonClassRecentlyCheck: string = "btn btn-tage-somethingelse";
                        if (book.recentlyChecked) {
                            buttonClassRecentlyCheck = 'btn btn-tage-recently-checked'
                        }

                        return <div key={book.id.id} className={"row m-0 p-0 userpage-drug-box"}>
                            <div className={"col m-0 p-0"}>
                                <div className={"row m-0 pl-3 pb-2 pt-2"}>
                                    <h6>
                                        {book.drug?.name + " - Nuvarande saldo: " + book.inventory}
                                    </h6>
                                </div>
                                <div className={"row m-0 p-0"}>

                                    <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                                        <button className={buttonClass}
                                                disabled={this.state.isDrugLord}
                                                onClick={() => this.flipAdminister(book.id)}>
                                            Ge
                                        </button>
                                    </div>

                                    <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                                        <button className={buttonClass}
                                                disabled={this.state.isDrugLord}
                                                onClick={() => this.flipMove(book.id)}>
                                            Flytta
                                        </button>
                                    </div>

                                    <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                                        <button className={buttonClass}
                                                disabled={this.state.isDrugLord}
                                                onClick={() => this.flipDiscard(book.id)}>
                                            Kassera
                                        </button>
                                    </div>

                                    <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                                        <button className={buttonClassRecentlyCheck}
                                                onClick={() => this.flipCheck(book.id)}>
                                            Kontroll
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>;
        }

        //TODO remove the books length argument. instead minimize the choice for locationName ....
        if (this.state.binders && this.state.binders.length > 0 && books.length < 1) {
            binders = <div className={"container"}>
                <div className={"row"}>
                    {
                        this.state.binders.map((binder, i: number) => {
                            if (binder.accessOnlyQr) {
                                return ""
                            }

                            return <div className={"card location-card p-0 ml-2 mr-2"} key={binder.id.id + " " + i}
                                        onClick={() => this.chooseBinder(binder)}>
                                <h5 className={"card-header m-0"}>{binder.locationName}</h5>
                                <div className={"card-body"}>
                                    <button className={"btn btn-drugbook"}>Välj</button>
                                </div>
                            </div>;
                        })
                    }
                </div>
            </div>
        }

        return <div aria-label={'drug book'}
                    className={"container pt-4"}>
            {binders}
            <div className={"justify-content-center"}>
                {binderHeader}
                {dispBook}
                {cancelButton}
            </div>
            {actionModal}
            {deliveryModal}
        </div>;
    }

    private setType(state: Readonly<state>) {
        if (state.discard) {
            return KnarkbokType.discard;
        }
        if (state.check) {
            return KnarkbokType.check;
        }
        if (state.administer) {
            return KnarkbokType.administer;
        }
        if (state.move) {
            return KnarkbokType.move;
        }

        return KnarkbokType.check;
    }

    private async chooseBinder(binder: Binder) {
        let binderId: BinderId = binder.id;
        const books: Book[] = await this.KBService.getBooksPerBinderId(binderId);

        this.setState(
            {
                books: books,
                currentBinder: binder
            }
        );
    }

    getBinder = () => {
        const drugBook: string = this.props.drugBook;

        const booksAndBinder: Promise<{
            books: Book[];
            binder: Binder;
            acceptDelivery: boolean
        }> = this.KBService.getBooksFromPathParam(drugBook);

        booksAndBinder.then((value: { books: Book[]; binder: Binder }) => {
            if (value) {
                this.setState({
                    books: value.books,
                    currentBinder: value.binder,
                    acceptDelivery: !!value.binder.acceptDelivery
                });
            }
        });
    }

    private flipAdminister(id: BookId) {
        this.setState({administer: true, currentId: id})
    }

    private flipDiscard(id: BookId) {
        this.setState({discard: true, currentId: id})
    }

    private flipCheck(id: BookId) {
        this.setState({check: true, currentId: id})
    }

    private flipMove(id: BookId) {
        this.setState({move: true, currentId: id})
    }

    turnOffModal = async () => {
        this.setState({
            check: false,
            administer: false,
            move: false,
            discard: false,
            currentId: null
        });

        if (this.state.currentBinder) {
            let binderId: BinderId = this.state.currentBinder.id;
            const books: Book[] = await this.KBService.getBooksPerBinderId(binderId);
            this.setState({books})
        }
    }

    turnOffDeliveryModal = () => {
        this.setState({showDeliveryPopup: false});
    }

    private handleDruglord() {
        this.setState({isDrugLord: !this.state.isDrugLord})
    }

    private flipShowDelivery() {
        this.setState({showDeliveryPopup: !this.state.showDeliveryPopup});
    }

    private routeHome() {
        window.location.hash = '#/';
    }
}

export default Knarkbokuserpage;
