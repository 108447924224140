import React from "react";
import frontendHash from './meta.json';
import {ERRORAPI} from "./infra/ErrorBoundary";
import {BackendService2} from "./infra/BackendService2";

const newerVersionExists = (responseVersion: any, localVersion: any) => {
    return JSON.stringify(responseVersion) !== JSON.stringify(localVersion)
};

interface props {
    content: React.JSX.Element
}

interface state {
    loading: boolean,
    isLatestVersion: boolean,
}

class CacheBuster extends React.Component<props, state> {
    constructor(props: props) {
        super(props);

        this.state = {
            loading: true,
            isLatestVersion: false,
        };
    }

    componentDidMount() {
        fetch('/meta.json')
            .then((response) => response.json()
                .catch((e) => console.log(e))
            )
            .then((meta) => {
                if (!meta) {
                    this.reportError("could not find meta json for user (cachebusting error) \n");

                    this.setState({
                        loading: false,
                        isLatestVersion: true
                    });
                } else {
                    const latestVersion = meta.version;
                    const currentVersion: string = frontendHash.version;

                    const shouldForceRefresh: boolean = newerVersionExists(latestVersion, currentVersion);
                    if (shouldForceRefresh) {
                        console.log(`Update found`);

                        this.setState({
                            loading: false,
                            isLatestVersion: false
                        });
                    } else {
                        console.log(`Up to date`);
                        this.setState({
                            loading: false,
                            isLatestVersion: true
                        });
                    }
                }
            });
    }

    render(): React.JSX.Element {
        const {loading, isLatestVersion} = this.state;
        if (!loading) {
            if (!isLatestVersion) {
                if (caches) {
                    caches.keys().then(function (names: string[]) {
                        for (let name of names) {
                            caches.delete(name);
                        }
                    });
                }

                window.location.reload();
            } else {
                return this.props.content
            }
        }

        return <div className={'container'}>
            <p>
                We are verifying that you use the latest version of LogEze.
            </p>
            <p>
                If this message doesn't disappear after 10 seconds, please reload the page and contact us at
                support@logeze.com
            </p>
        </div>
    }

    private async reportError(message: string) {
        let errorMsg: string = message;
        try {
            const userAgent: string = navigator.userAgent || "unable to find userAgent";
            const platform: string = navigator.platform || "unable to find platform";
            errorMsg += ", UserAgent: <" + userAgent + ">";
            errorMsg += ", Platform: <" + platform + ">";

            BackendService2.post(errorMsg, ERRORAPI);
        } catch (e) {
            BackendService2.post("Det uppstod ett fel när vi skulle raportera om att det uppstod ett fel (cachebuster)", ERRORAPI);
        }
    }
}

export default CacheBuster;
